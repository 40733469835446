import React from "react";
import { Link } from "react-router-dom";
import footerImgSm from "../../assets/Footer.webp";
import footerImgLg from "../../assets/Footer.webp";
import "./footer.css";
import { scrollToTop } from "../../utils/helpers";

const Footer = () => {
  return (
    <section className="footer-section">
      <div>
        <hr></hr>
      </div>
      <div className="footer">
        <div className="footer-menu">
          <div>
            <h2>Conecte con un profesional</h2>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/encuentra-un-agente"}
              onClick={scrollToTop}
            >
              Encuentra Un Agente
            </Link>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/encuentra-un-prestamista"}
              onClick={scrollToTop}
            >
              Encuentra Un Prestamista
            </Link>
          </div>

          <div>
            <h2>Recursos Adicionales</h2>
            <a
              className="text-shadow-sm decoration-none"
              href={"https://blog.buscatucasa.com/Propiedas-unicas/"}
            >
              Propiedas Unicas
            </a>
            <a
              className="text-shadow-sm decoration-none"
              href={"https://blog.buscatucasa.com/Education/"}
            >
              Educación para compradores/vendedores
            </a>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/contactanos"}
              onClick={scrollToTop}
            >
              Contactanos
            </Link>
          </div>

          <div>
            <h2>Centro de Servicios Profesionales</h2>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/inicio-de-sesión-de-usuario"}
              onClick={scrollToTop}
            >
              Inicio de sesión de agente
            </Link>
            <Link
              to={"/página-de-precios"}
              className="text-shadow-sm  decoration-none"
              onClick={scrollToTop}
            >
              Inscripción de agente bienes raíces
            </Link>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/inscripción-de-prestamista"}
              onClick={scrollToTop}
            >
              Inscripción de prestamista
            </Link>
          </div>
        </div>

        <img
          className="footer-image"
          alt="A row of inviting houses"
          srcSet={`${footerImgSm} 500w, ${footerImgLg} 700w`}
          sizes="(max-width: 500px) 500px, (max-width: 700px) 700px, 1280px"
          width={"740px"}
          height={"165px"}
          src={footerImgLg}
        />
        <p className="jason text-shadow-sm" style={{ textAlign: "center" }}>
          Jason Galaz, eXp Realty. #344914 p: (888)519-5113
        </p>
        <p className="brain-jar-link">
          Built by&nbsp;
          <a
            className="decoration-none"
            href="https://brainjar.net/"
            target="blank"
          >
            Brain Jar
          </a>
        </p>
      </div>
    </section>
  );
};

export default Footer;
