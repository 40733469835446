import React, { useState, useEffect } from "react";
import { addFile } from "../../utils/api";

const ImageUpload = () => {
  const [profileImage, setProfileImage] = useState("");

  const handleImageUpload = async () => {
    // Upload profile photo
    addFile(profileImage)
      .then((res) => res.json())
      .then((data) => {
        // Send the name of the file saved in Findahome's files
        window.parent.postMessage(`fileName:${data.fileName}`, "*");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = (e) => {
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setProfileImage(formData);
  };

  // ---------------- MESSAGE CENTER -----------------
  window.onmessage = function (event) {
    console.log(event.data);
    // Listens for Parent Form Submission
    if (typeof event.data === "string" && event.data.includes("Submitting")) {
      // Lets the Parent Window know if an image has been uploaded
      if (profileImage !== "") {
        handleImageUpload();
      } else {
        window.parent.postMessage(`No Image For Upload`, "*");
      }
    }
  };

  return (
    <div className="loans-mb-3 agent-signup-photo-upload">
      <label htmlFor="photo" className="loans-form-label">
        Agent Profile Photo Upload *
      </label>
      <input
        type="file"
        name="photo"
        id="photo"
        onChange={handleImageChange}
        accept="image/png, image/jpeg"
        required
      />
    </div>
  );
};

export default ImageUpload;
