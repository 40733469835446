import React from "react";
import headerImg from "../../assets/busca-logo.webp";
import { Link } from "react-router-dom";
import "./header.css";

const Header = ({ setCurrentPage }) => {
  function toggleMenu() {
    const menu = document.querySelector(".menu");
    const header = document.querySelector(".header");
    const closeIcon = document.querySelector(".closeIcon");
    const menuIcon = document.querySelector(".menuIcon");

    if (menu.classList.contains("showMenu")) {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
      header.classList.remove("front");
    } else {
      header.classList.add("front");
      menu.classList.add("showMenu");
      closeIcon.style.display = "block";
      menuIcon.style.display = "none";
    }
  }

  return (
    <section>
      <div className="header">
        <div>
          <Link to={"/"}>
            <img className="logo" alt="Find A Home Logo" src={headerImg} />
          </Link>
        </div>

        {/* <!-- Main Menu --> */}
        <ul className="menu">
          <li>
            <a
              href={"https://www.findahome.com/"}
              target="blank"
              className="menuItem text-shadow decoration-none"
            >
              En Ingles
            </a>
          </li>
          <li>
            <a
              href={"https://buscatucasa.ruuster.com/signup"}
              target="blank"
              className="menuItem text-shadow decoration-none"
            >
              App Download
            </a>
          </li>
          <li>
            <Link
              to={"/prestamos"}
              className="menuItem text-shadow decoration-none"
            >
              Prestamos
            </Link>
          </li>
          <li>
            <a
              href={"/buscar"}
              className="menuItem text-shadow decoration-none"
            >
              Comprar
            </a>
          </li>
          <li>
            <a
              href={"https://findahome.hifello.com/lp/65f0e577e2f0f9002d7feaa1"}
              target="blank"
              className="menuItem text-shadow decoration-none"
            >
              Vender
            </a>
          </li>
        </ul>
      </div>
      <button className="hamburger">
        <i
          className="menuIcon material-icons text-shadow"
          onClick={() => toggleMenu()}
        >
          menu
        </i>
        <i
          className="closeIcon material-icons text-shadow"
          onClick={() => toggleMenu()}
        >
          close
        </i>
      </button>
    </section>
  );
};

export default Header;
