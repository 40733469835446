import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import Auth from "../../../../utils/auth";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../../utils/api";
import { updateUser } from "../../../../utils/api";
import editImage from "../../../../assets/edit.png";
import imagePath from "../../../../utils/image";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  const [agent, setAgents] = useState("");

  // Run Query Once
  useEffect(() => {
    // User token
    let currentUser;
    if (Auth.loggedIn() !== false) {
      currentUser = Auth.getUser();

      getUserProfile(currentUser.id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // Set the from states with db data
          setUser({
            id: data.id,
            name: data.name,
            email: data.email,
          });

          setAgents(data.lenders[0]);

          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      navigate(`/inicio-de-sesión-de-usuario`);
    }
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  const getArea = (agent) => {
    const city = agent.areas_served.split("; ")[0];
    return city;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (email === user.email || email.trim() === "") {
      return;
    } else {
      const userData = {
        email: email,
      };

      updateUser(userData, user.id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    }
  };

  if (isLoading) {
    return <>loading...</>;
  }

  return (
    <div className="agent-portal busca">
      <Header user={user} />

      {/* Main */}
      <main>
        <div className="ap-dashboard">
          <main>
            {/* Account Information */}
            <div className="ap-account-details">
              <h1>Detalles de la Cuenta</h1>

              {isEditing === true ? (
                <form onSubmit={handleFormSubmit} className="ap-details-input">
                  <div>
                    <label htmlFor="email" className="loans-form-label">
                      {" "}
                      Correo electrónico *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="loans-form-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="ap-details-btns">
                    <button onClick={() => setIsEditing(false)}>
                      Cancelar
                    </button>
                    <button className="primary" type="submit">
                      Actualizar
                    </button>
                  </div>

                  {showError ? (
                    <div className="message-container">
                      <p className="error-message">
                        Algo salió mal con tu solicitud, por favor inténtalo de
                        nuevo más tarde.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </form>
              ) : (
                <p>
                  Correo electrónico: {user.email}{" "}
                  <span>
                    <img
                      src={editImage}
                      alt="Edit information."
                      onClick={() => setIsEditing(true)}
                    />
                  </span>
                </p>
              )}
            </div>

            {/* Card Preview */}
            <div>
              {agent.isDeleted === true ? (
                <p className="ap-card-alert">
                  Esta es una vista previa de cómo otros verán su tarjeta de
                  perfil. Una vez que se apruebe su perfil, podrá editar su
                  información haciendo clic en “Editar perfil”.
                </p>
              ) : (
                <></>
              )}

              <div className="ap-card-conatiner">
                <div className="agent-search-result-card ap-card">
                  <div className={!agent.image ? "" : "agent-card-top"}></div>
                  <div className="agent-card-content">
                    <img src={displayPhoto(agent.image)} alt="Agent Headshot" />
                    <h3>
                      {agent.first_name} {agent.last_name}
                    </h3>
                    <p>{getArea(agent)}</p>
                    <p>{agent.email}</p>
                    <p>{agent.phone_number}</p>
                    <div className="agent-card-btn-container">
                      <div>
                        <button
                          className={
                            agent.isDeleted === false
                              ? "text-shadow agent-card-btn"
                              : "text-shadow agent-card-btn disabled"
                          }
                          onClick={() =>
                            navigate(`/portal-de-prestamistas-edit/${agent.id}`)
                          }
                        >
                          Editar perfil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
