import React from "react";
import "./buscaAgentCard.css";
import imagePath from "../../utils/image";

const BuscaAgentCard = ({ agent }) => {
  // console.log(agent);

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  const getArea = (agent) => {
    const searchTerm = localStorage.getItem("agentSearchTerm");
    if (searchTerm) {
      return searchTerm;
    } else {
      const city = agent.areas_served.split("; ")[0];
      return city;
    }
  };

  return (
    <div className="agent-search-result-card busca">
      <div className={!agent.image ? "" : "agent-card-top"}></div>
      <div className="agent-card-content">
        <img src={displayPhoto(agent.image)} alt="Agent Headshot" />
        <h3>
          {agent.first_name} {agent.last_name}
        </h3>
        <p>{getArea(agent)}</p>
        <p>{agent.email}</p>
        <p>{agent.phone_number}</p>
        <div className="agent-card-btn-container">
          <a href={`/perfil-agente/${agent.id}`}>
            <button className="text-shadow agent-card-btn">Ver perfil</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BuscaAgentCard;
