import "./style.css";
import check from "../../../assets/check.png";

const PricingGrid = ({
  handleFree,
  handleStandard,
  handlePro,
  handleEnterprise,
}) => {
  return (
    <div className="pricing-desktop">
      <div className="pricing-container">
        <div className="pricing-banner">
          <p>Precios y características</p>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              {/* Free Heading */}
              <th className="pricing-th">
                <h2>Free</h2>
                <div>
                  <p className="pricing-price">$0</p>
                  <p className="pricing-conditions">Por Mes</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Herramientas esenciales. Conexión de referidos y
                  capacitaciones mensuales para aumentar sus habilidades como
                  agente.
                </p>
              </th>
              {/* Standard Heading */}
              <th className="pricing-th">
                <h2>Standard</h2>
                <div>
                  <p className="pricing-price">$89</p>
                  <p className="pricing-conditions">Por Mes</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Aumento de visibilidad en nuestra página web y acceso como
                  agente a la app de Busca Tu Casa. Nivel para agentes listos
                  para aumentar su alcance y compromiso con el cliente.
                </p>
              </th>
              {/* Pro Heading */}
              <th className="pricing-th">
                <h2>Pro</h2>
                <div>
                  <p className="pricing-price">$197</p>
                  <p className="pricing-conditions">Por Mes</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  <span className="font-small">
                    Integración diseñada para conectarse directamente con su CRM
                    desde la aplicación móvil de Busca Tu Casa. Opciones
                    disponibles para agentes individuales o líderes de equipo
                    que buscan generar más clientes y tener una gestión
                    eficiente.
                  </span>
                </p>
              </th>
              {/* Enterprise Heading */}
              <th className="pricing-th">
                <h2>Para empresas e iniciativas</h2>
                <div>
                  <p className="pricing-conditions">Solicite más información</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Soluciones personalizadas, diseñadas para equipos y agencias
                  que buscan mejorar su marca para elevar sus negocios.
                </p>
              </th>
            </tr>
          </thead>

          {/* Features */}
          <tbody>
            <tr>
              <td>Perfil individual en el directorio en Busca Tu Casa</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Red de referidos</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Centro de marketing</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Capacitaciones mensuales de Zoom</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Acceso a nuestra aplicación móvil</td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Perfil de agente destacado en Buscatucasa.com</td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Integración de Busca Tu Casa app con su CRM. Opciones: líder de
                equipo o agente individual (solo FUB y Sierra)
              </td>
              <td></td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Marketing mejorado</td>
              <td></td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                Desarrollo de aplicaciones personalizadas para equipos y
                agencias
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr className="no-hover">
              <td></td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFree();
                  }}
                >
                  Empezar
                </button>
              </td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStandard();
                  }}
                >
                  Comprar ahora
                </button>
              </td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePro();
                  }}
                >
                  Comprar ahora
                </button>
              </td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEnterprise();
                  }}
                >
                  Solicitar demostración
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingGrid;
