import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../utils/api";
import "./forgotpassword.css";

const ForgotPassword = () => {
  // set states
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      //request to "/api/users/forgot-password"
      const response = await forgotPassword({ email });

      if (response.ok) {
        setErrorMessage(
          "Revise su correo electrónico para ver el enlace para restablecer su contraseña"
        );
        setTimeout(() => {
          navigate("/otp", { state: { email } });
        }, 3000);
      } else {
        console.error(
          "Se produjo un error al solicitar el restablecimiento de contraseña."
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="password-reset-page busca">
      <form className="password-form" onSubmit={handleSubmit}>
        <div className="pw-container">
          <p className="pw-text">
            Ingrese la dirección de correo electrónico asociada con su cuenta y
            le enviaremos un enlace para actualizar su contraseña.
          </p>
          <div className="password-input">
            <input
              placeholder="Correo electrónico"
              type="email"
              id="pw-email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button className="password-btn" type="submit">
            Continuar
          </button>
          {errorMessage && <p className="message">{errorMessage}</p>}
          <span className="span">
            <p style={{ color: "#343459" }}>¿No tienes una cuenta?</p>
            <Link to="/página-de-precios" className="signup">
              &nbsp; &nbsp; Inscribirse
            </Link>
          </span>
        </div>
      </form>
    </section>
  );
};

export default ForgotPassword;
