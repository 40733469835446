import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages/Components
import Contact from "./pages/Contact/index";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home/index";
import HomeLoans from "./pages/HomeLoans";
import Login from "./pages/Login";
import OTPValidation from "./pages/OTP";
import Search from "./pages/Search/index";
import SignupSuccess from "./pages/SignupSuccess";
import ImageUpload from "./components/ImageUpload";

// Find An Agent Feature
import AgentPortal from "./pages/FindAAgent/Portal/Dashboard/index";
import AgentPortalEdit from "./pages/FindAAgent/Portal/Edit/index";
import AgentProfile from "./pages/FindAAgent/Profile";
import AgentSearch from "./pages/FindAAgent/Search";
import AgentSignup from "./pages/FindAAgent/Signup";
import PricingPage from "./pages/FindAAgent/Pricing";

// Find a Lender Feature
import LenderPortal from "./pages/FindALender/Portal/Dashboard/index";
import LenderPortalEdit from "./pages/FindALender/Portal/Edit/index";
import LenderProfile from "./pages/FindALender/Profile";
import LenderSearch from "./pages/FindALender/Search";
import LenderSignup from "./pages/FindALender/Signup";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactanos" element={<Contact />} />
        <Route path="/prestamos" element={<HomeLoans />} />
        <Route path="/buscar" element={<Search />} />
        <Route path="/página-de-precios" element={<PricingPage />} />
        <Route path="/fahiu2024" element={<ImageUpload />} />

        {/* Authentication */}
        <Route path="/inicio-de-sesión-de-usuario" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OTPValidation />} />

        {/* Agent In the Area Routes */}
        <Route path="/encuentra-un-agente" element={<AgentSearch />} />
        <Route path="/perfil-agente/:id" element={<AgentProfile />} />
        <Route path="/agente-inscribirse" element={<AgentSignup />} />
        <Route path="/registro-éxito" element={<SignupSuccess />} />
        <Route path="/portal-de-agentes" element={<AgentPortal />} />
        <Route
          path="/portal-de-agentes-editar/:id"
          element={<AgentPortalEdit />}
        />

        {/* Find a Lender Routes */}
        <Route path="/encuentra-un-prestamista" element={<LenderSearch />} />
        <Route path="/perfil-prestamista/:id" element={<LenderProfile />} />
        <Route path="/inscripción-de-prestamista" element={<LenderSignup />} />
        <Route path="/portal-de-prestamistas" element={<LenderPortal />} />
        <Route
          path="/portal-de-prestamistas-edit/:id"
          element={<LenderPortalEdit />}
        />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
