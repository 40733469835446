import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./signupSuccess.css";
import { postNotice, updateAgent } from "../../utils/api";

const SignupSuccess = () => {
  const plan_type = localStorage.getItem("plan_type");
  const agent = JSON.parse(localStorage.getItem("agent"));

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    console.log(plan_type, agent);

    if (plan_type) {
      // Update the Agent's Plan Type
      updateAgent({ plan_type: plan_type }, agent.id)
        .then((res) => res.json())
        .then((data) => {
          const noticeData = {
            content: `${agent.name} subscribed to the ${plan_type} plan.`,
            actions: "",
            website: agent.website ? agent.website : "Busca",
            user_id: null,
            agent_id: null,
          };

          // Notify the Admin
          postNotice(noticeData)
            .then((res) => res.json())
            .then((data) => {
              localStorage.removeItem("plan_type");
              localStorage.removeItem("agent");
              setLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="signup-success-page">
          <h1>Processing...</h1>

          <p>Please do not refresh the page.</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />

      <div className="signup-success-page busca">
        <h1>¡Éxito!</h1>

        <p>
          ¡Gracias por registrarte! Actualmente estamos revisando la información
          de su perfil para su aprobación. Para acceder y administrar su cuenta,
          visite la página de{" "}
          <a href="/inicio-de-sesión-de-usuario" className="link">
            inicio de sesión del agente.
          </a>{" "}
        </p>

        <Link to={"/"}>
          <button>Volver a Casa</button>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default SignupSuccess;
