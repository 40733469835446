import "./style.css";
import check from "../../../assets/check.png";

const PricingCards = ({
  handleFree,
  handleStandard,
  handlePro,
  handleEnterprise,
}) => {
  return (
    <div className="pricing-mobile">
      <div className="grid-3">
        {/* Free Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Free</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$0</p>
                    <p className="pricing-conditions ">Por Mes</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Herramientas esenciales. Conexión de referidos y
                    capacitaciones mensuales para aumentar sus habilidades como
                    agente.
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>Perfil individual en el directorio en Busca Tu Casa</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Red de referidos</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Centro de marketing</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Capacitaciones mensuales de Zoom</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFree();
                    }}
                  >
                    Empezar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Standard Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Standard</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$89</p>
                    <p className="pricing-conditions">Por Mes</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Aumento de visibilidad en nuestra página web y acceso como
                    agente a la app de Busca Tu Casa. Nivel para agentes listos
                    para aumentar su alcance y compromiso con el cliente.{" "}
                    <br></br>
                    <br></br>Incluye todo lo del nivel Gratuito plus:
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>Acceso a nuestra aplicación móvil</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Perfil de agente destacado en Buscatucasa.com</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handleStandard();
                    }}
                  >
                    Comprar ahora
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Pro Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Pro</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$197</p>
                    <p className="pricing-conditions">Por Mes</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Integración diseñada para conectarse directamente con su CRM
                    desde la aplicación móvil de Busca Tu Casa. Opciones
                    disponibles para agentes individuales o líderes de equipo
                    que buscan generar más clientes y tener una gestión
                    eficiente. <br></br>
                    <br></br>Incluye todo lo del nivel Estándar plus:
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>
                  Integración de Busca Tu Casa app con su CRM. Opciones: líder
                  de equipo o agente individual (solo FUB y Sierra)
                </td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Marketing mejorado</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePro();
                    }}
                  >
                    Comprar ahora
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Enterprise Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Para empresas e iniciativas</h2>
                  <div className="mobile-div">
                    <p className="pricing-conditions">
                      Solicite más información
                    </p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Soluciones personalizadas, diseñadas para equipos y agencias
                    que buscan mejorar su marca para elevar sus negocios.{" "}
                    <br></br>
                    <br></br>Incluye todo lo del nivel Pro más:
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>
                  {" "}
                  Desarrollo de aplicaciones personalizadas para equipos y
                  agencias
                </td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEnterprise();
                    }}
                  >
                    Solicitar demostración
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
