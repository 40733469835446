import React, { useState } from "react";
import "./style.css";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import PricingGrid from "./grid";
import PricingCards from "./cards";
import Modal from "./modal";

const AgentSignup1 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleFree = () => {
    localStorage.removeItem("plan_type");
    window.location.href = "/agente-inscribirse";
  };
  const handleStandard = () => {
    localStorage.setItem("plan_type", "Standard");
    window.location.href = "/agente-inscribirse";
  };
  const handlePro = () => {
    localStorage.setItem("plan_type", "Pro");
    window.location.href = "/agente-inscribirse";
  };
  const handleEnterprise = () => {
    setModalOpen(true);
  };

  return (
    <div>
      <Header />

      <div className="pricing-header">
        <h1>Encuentre un agente inmobiliario</h1>

        <h2>
          Impulse su negocio inmobiliario con nuestro CRM y networking
          personalizados Soluciones
        </h2>

        <p>
          Ya sea que sea un agente en solitario o un equipo de bienes raíces en
          crecimiento, nuestro Las herramientas de networking están diseñadas
          para agilizar sus procesos, ayudarle cierre acuerdos más rápido y
          construya relaciones duraderas. Elige el paquete que se adapta a su
          negocio y desbloquea funciones como clientes potenciales generación y
          colaboración perfecta.
        </p>
      </div>

      <main className="pricing-main">
        {/* Desktop View*/}
        <PricingGrid
          handleFree={handleFree}
          handleStandard={handleStandard}
          handlePro={handlePro}
          handleEnterprise={handleEnterprise}
        />

        {/* Mobile View */}
        <PricingCards
          handleFree={handleFree}
          handleStandard={handleStandard}
          handlePro={handlePro}
          handleEnterprise={handleEnterprise}
        />
      </main>

      <div className="pricing-footer">
        <p>Su red, sus ofertas, su éxito: todo en una plataforma.</p>
      </div>

      <Footer />

      {modalOpen ? <Modal setModalOpen={setModalOpen} /> : <></>}
    </div>
  );
};

export default AgentSignup1;
