import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Contact = () => {
  // https://docs.followupboss.com/docs/start-here-brand-new-integration
  // POST https://api.followupboss.com/v1/events
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = {
      firstName: firstName,
      lastName: lastName,
      message: message,
      email: email,
      phoneNumber: phoneNumber,
    };

    fetch("api/busca/sendNewLead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setShowSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setShowError(true);
      });
  };

  return (
    <div>
      <Header />

      <main>
        {/* Page Heading */}
        <div className="contact-form-heading">
          <h2>Contactanos</h2>
          <p>Complete el siguiente formulario y nos comunicaremos mañana.</p>
          <hr className="contact-form-hr" />
        </div>

        {/* The Form */}
        <div className="contact-form-container busca">
          <div className="contact-main-wrapper">
            <div className="contact-form-wrapper">
              <form onSubmit={handleFormSubmit}>
                <div className="contact-input-flex">
                  <div>
                    <label htmlFor="firstname" className="contact-form-label">
                      Nombre *
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="Nombre"
                      className="contact-form-input"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastname" className="contact-form-label">
                      Apellido *
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Apellido"
                      className="contact-form-input"
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="contact-input-flex">
                  <div>
                    <label htmlFor="email" className="contact-form-label">
                      Correo Electrónico *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@gmail.com"
                      className="contact-form-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="contact-form-label">
                      Número de teléfono *
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="(319) 555-0115"
                      className="contact-form-input"
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="contact-form-label">
                    Mensaje *
                  </label>
                  <textarea
                    rows="6"
                    name="message"
                    id="message"
                    placeholder="Escribe tu mensaje"
                    className="contact-form-input"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>

                {showSuccess ? (
                  <div className="message-container">
                    <p className="success-message">
                      Su mensaje ha sido enviado, ¡nos comunicaremos con usted
                      en breve!
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {showError ? (
                  <div className="message-container">
                    <p className="error-message">
                      Algo salió mal con tu solicitud, por favor inténtalo de
                      nuevo más tarde.
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                <button className="contact-btn text-shadow" type="submit">
                  Enviar mensaje
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
