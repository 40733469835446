import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { loginUser, updatePassword } from "../../utils/api";
import Auth from "../../utils/auth";
import "./login.css";
import { Link } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  //otp password states
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      email: email,
      password: password,
    };

    // Try to Log the User in
    try {
      const response = await loginUser(formData);

      if (!response.ok) {
        // Handle unsuccesfull login
        const { message } = await response.json();

        console.log(message);

        if (message === "Incorrect password for this account.") {
          setErrorMessage(
            "Su nombre de usuario o contraseña son incorrectos, inténtelo nuevamente."
          );
        } else if (message === "There is no account found with this email.") {
          setErrorMessage(
            "No se ha encontrado ninguna cuenta con este correo electrónico."
          );
        } else {
          setErrorMessage("Algo salió mal, por favor inténtalo de nuevo.");
        }
      } else {
        // If successfull, save token and re-route to their "dashboard"
        const { token, user_type } = await response.json();
        Auth.login(token);

        if (user_type === "Agent") {
          navigate(`/portal-de-agentes`);
        } else if (user_type === "Lender") {
          navigate(`/portal-de-prestamistas`);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handlePasswordReset = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Las nuevas contraseñas no coinciden.");
      return;
    }

    try {
      //request to "/api/users/update-password"
      const response = await updatePassword({ email, otp, newPassword });

      if (response.ok) {
        setErrorMessage(
          "Contraseña actualizada correctamente. Por favor inicie sesión con su nueva contraseña."
        );
        //hide change password inputs
        setShowChangePassword(false);
      } else {
        const { message } = await response.json();
        setErrorMessage(message || "No se pudo actualizar la contraseña.");
      }
    } catch (error) {
      console.error("Hubo un error al actualizar la contraseña:", error);
      setErrorMessage("Se produjo un error al actualizar la contraseña.");
    }
  };
  return (
    <div>
      <Header />

      <div className="login-page busca">
        <h1>¡Bienvenido de nuevo!</h1>

        <p className="login-message">
          Este inicio de sesión es para nuestros agentes y prestamistas
          existentes de Find a Home. Si Eres nuevo aquí, puedes crear una cuenta
          de agente gratuito visitando el{" "}
          <a href="/página-de-precios">Registro de agente</a>
          página, o crear un prestamista gratuito cuenta visitando el{" "}
          <a href="/inscripción-de-prestamista">Registro de prestamista</a>{" "}
          página.
        </p>

        <form
          onSubmit={showChangePassword ? handlePasswordReset : handleFormSubmit}
        >
          {!showChangePassword ? (
            <>
              <div className="agent-input-group">
                <label htmlFor="email" className="agent-form-label">
                  Correo electrónico *
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Correo electrónico"
                  className="agent-form-input"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="agent-input-group">
                <label htmlFor="password" className="agent-form-label">
                  Contraseña *
                </label>
                <input
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  className="agent-form-input"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button className="agent-btn" type="submit">
                Enviar
              </button>

              {/* Display Error Message */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </>
          ) : (
            <>
              {/* Change password inputs */}
              <label htmlFor="newPassword">Nueva contraseña</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <label htmlFor="confirmNewPassword">
                Confirmar nueva contraseña
              </label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
              <input
                type="submit"
                value="Change Password"
                aria-label="change-password-button"
              />
              {/* Display Error Message */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </>
          )}
        </form>
        <div className="forgot-password">
          {/* Forgot Password */}
          <Link to="/forgot-password"> Restablecer contraseña</Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
