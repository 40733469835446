import React from "react";
import "./search.css";
import Header from "../../components/Header";

const Search = () => {
  return (
    <div>
      <Header />
      <main>
        {/* Iframe in the Page from Ruuster */}
        <div className="iframe-results-container">
          <iframe
            id="listing-integrations"
            src="https://buscatucasa.ruuster.com/agent/jasongalaz/iframe?slug=jasongalaz"
            title="Listing search"
            style={{
              width: "100%",
              height: "calc(100vh - 108px)",
              overflow: "hidden",
              border: "none",
            }}
          ></iframe>
        </div>
      </main>
    </div>
  );
};

export default Search;
