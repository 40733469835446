import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import EmailIcon from "../../../assets/email.png";
import PhoneIcon from "../../../assets/phone.png";
import { getAgentProfile } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import imagePath from "../../../utils/image";

const AgentProfile = () => {
  const navigate = useNavigate();
  const [agent, setAgent] = useState([]);
  const [areas, setAreas] = useState([]);
  const [iframe, setIframe] = useState("");
  const [isLoading, setLoading] = useState(true);

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  const getArea = () => {
    const searchTerm = localStorage.getItem("agentSearchTerm");
    if (searchTerm) {
      return searchTerm;
    } else {
      const city = agent.areas_served.split("; ")[0];
      return city;
    }
  };

  const url = window.location.href;
  const agent_id = url.split("/")[4];

  useEffect(() => {
    // Get agent Info
    getAgentProfile(agent_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        setAgent(data);
        setAreas(data.areas_served.split("; "));
        if (data.iframe_link !== null) {
          setIframe(data.iframe_link);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (iframe !== "") {
      // Check for Div
      const div = document.getElementsByClassName("iframeDiv");

      if (!div) {
        checkForDivEl();
      } else {
        addIframe();
      }

      function checkForDivEl() {
        const elObserver = new MutationObserver((_mutationList, observer) => {
          const div = document.getElementsByClassName("iframeDiv");
          if (div) {
            addIframe();
            observer.disconnect(); // Remove observer
          }
        });

        elObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }

      function addIframe() {
        // Insert a code directly into the div
        const target = document.getElementById("iframeDiv");
        target.innerHTML = "";
        target.innerHTML += `${iframe}`;
      }
    }
  }, [iframe]);

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="min-height"></div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="breadcrumb">
        <p className="text">Directorio de agentes de Busca Tu Casa / </p>
        <p
          className="back-button"
          onClick={() => {
            navigate(`/encuentra-un-agente`);
          }}
        >
          Volver a la búsqueda
        </p>
      </div>

      <main className="agent-profile-page busca">
        <div className="agent-col-1">
          <h1>
            {agent.first_name} {agent.last_name}
          </h1>
          <p>{getArea()} Agente de bienes raíces</p>
          <img
            src={displayPhoto(agent.image)}
            alt="Agent Headshot"
            className={!agent.image ? "agent-photo" : "agent-photo with-border"}
          />

          <div className="agent-info">
            <h2>Información del contacto</h2>
            <div className="flex">
              <img src={EmailIcon} alt="Email Icon" className="icon" />
              <p>{agent.email}</p>
            </div>
            <div className="flex">
              <img src={PhoneIcon} alt="Email Icon" className="icon" />
              <p>{agent.phone_number}</p>
            </div>
          </div>

          <div className="agent-info">
            <h2>Idiomas</h2>
            <p>{agent.languages_spoken}</p>
          </div>

          <div className="agent-info">
            <h2>Número de licencia estatal</h2>
            <p>{agent.state_license_numbers}</p>
          </div>

          <div className="agent-info">
            <h2>Agencia de bienes raíces</h2>
            <p>
              {agent.brokerage} {agent.brokerage_number}
            </p>
          </div>

          <div className="agent-info">
            <h2>Áreas de servicio</h2>
            {areas.map((area) => (
              <p key={area}>{area}</p>
            ))}
          </div>
        </div>
        <div className="agent-col-2">
          <p>{agent.profile_description}</p>
          <div
            id="iframeDiv"
            className="iframeDiv"
            style={{ overflow: "auto", maxWidth: "90vw" }}
          ></div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default AgentProfile;
